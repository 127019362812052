import React, { useState } from 'react';
import { Link, SecondaryMenuProps } from '@components/containers/SecondaryMenu';
import AccordionTitle from '@components/views/AdditionalMenuMobile/AccordionTitle';
import AccordionItems from '@components/views/AdditionalMenuMobile/AccordionItems';
import { formatOwnLinksToAdditionalMenuLinks } from 'src/utils/formatOwnLinksToAdditionalMenuLinks';
import { AdditionalMobileMenuProvider } from '@components/views/AdditionalMenuMobile/additionalMobileMenu.context';

const AdditionalMenu = (props: SecondaryMenuProps) => {
  const {
    current_page,
    links,
    buttons,
    label,
    own_links,
    use_own_links,
    links_select,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const hasOwnLinksCollection =
    use_own_links && own_links && own_links.items && own_links.items.length > 0;
  const linksData: Link[] = hasOwnLinksCollection
    ? formatOwnLinksToAdditionalMenuLinks(own_links.items || [])
    : links;
  const additionalLinks = links_select?.items || [];

  const handleIsOpen = () => setIsOpen((prevState) => !prevState);

  const title =
    current_page && current_page.post_title
      ? label || current_page.post_title
      : label || 'Menu';

  return (
    <AdditionalMobileMenuProvider handleMenuToggle={handleIsOpen}>
      <div>
        <AccordionTitle title={title} isOpen={isOpen} onClick={handleIsOpen} />
        <div className="h-1 w-full bg-neutral-1" />
        {isOpen && linksData && (
          <AccordionItems
            links={linksData.filter((link) => {
              return !(
                current_page?.is_root_post && link.url === current_page?.url
              );
            })}
            additionalLinks={additionalLinks}
            buttons={buttons}
          />
        )}
      </div>
    </AdditionalMobileMenuProvider>
  );
};

export default AdditionalMenu;
