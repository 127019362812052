import Container from '@components/containers/Container';
import { CloseDropdownHandler } from '../Dropdown';
import Link from 'next/link';
import NavigationButton from './atoms';
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import useScrollElement from './useScrollElement';
import useSetFlexElementMaxWidth from 'src/hooks/useSetFlexElementMaxWidth';
import Button from '@components/views/Button';
import { SecondaryMenuProps } from '@components/containers/SecondaryMenu';
import LinksList from '@components/views/AdditionalMenu/LinksMenu/LinksList';

/**
 *
 * @link https://www.figma.com/file/bEoq4a8N0Yy6hvVvwAQxiS/WSC-%3A%3A-UI-Design?node-id=192%3A4255
 */

const AdditionalMenu = (props: SecondaryMenuProps) => {
  const [isElementScrollable, setIsElementScrollable] = useState(false);

  const dynamicWidthElementRef = useRef<HTMLUListElement>(null);
  const staticElementRef = useRef<HTMLDivElement>(null);
  const wrapperElementRef = useRef<HTMLDivElement>(null);
  const scrollableElementRef = useRef<HTMLUListElement>(null);

  const dynamicMaxWidthValue = useSetFlexElementMaxWidth(
    dynamicWidthElementRef.current,
    staticElementRef.current,
    wrapperElementRef.current,
  );

  const {
    scrollX,
    isScrollEnd,
    sliderPrevHandler,
    sliderNextHandler,
    scrollCheckHandler,
  } = useScrollElement(scrollableElementRef);

  const {
    links,
    buttons,
    own_links,
    use_own_links,
    current_page,
    label,
    replace_label_with_dropdown,
    links_select,
  } = props;

  const hasOwnLinksCollection =
    use_own_links &&
    !!own_links &&
    !!own_links.items &&
    own_links.items.length > 0;
  const hasDefaultLinksCollection = links && links.length > 0;
  const hasLinksCollection = hasDefaultLinksCollection || hasOwnLinksCollection;

  useEffect(() => {
    if (dynamicMaxWidthValue && scrollableElementRef.current) {
      const isScrollable =
        dynamicMaxWidthValue < scrollableElementRef.current?.scrollWidth;
      setIsElementScrollable(isScrollable);
    }
  }, [dynamicMaxWidthValue, scrollableElementRef]);

  let dropdownRef: CloseDropdownHandler;

  const hasButtonsCollection = buttons && buttons.length > 0;
  const ownLinksLabel = label || (current_page && current_page.post_title);
  const dropdownLinks = replace_label_with_dropdown
    ? links_select
    : { items: [] };

  return (
    <div className="bg-neutral-0">
      <Container className="relative">
        <div
          className="flex flex-wrap md:flex-nowrap justify-between items-center py-20 gap-16 md:mr-16 "
          ref={wrapperElementRef}
        >
          {hasLinksCollection && (
            <nav
              style={{
                maxWidth: dynamicMaxWidthValue ?? 'none',
              }}
              ref={dynamicWidthElementRef}
              className="relative w-full"
            >
              {isElementScrollable && scrollX !== 0 && (
                <NavigationButton onClick={sliderPrevHandler} variant="LEFT" />
              )}

              {isElementScrollable && !isScrollEnd && (
                <NavigationButton onClick={sliderNextHandler} />
              )}

              <ul
                className="flex gap-16 pr-16 mr-16 items-center text-sm text-neutral-9 whitespace-nowrap overflow-x-scroll scrollbar-hide"
                onScroll={() => {
                  scrollCheckHandler();
                  dropdownRef?.hideDropdown?.();
                }}
                ref={scrollableElementRef}
              >
                {replace_label_with_dropdown && !hasOwnLinksCollection && (
                  <LinksList
                    label={ownLinksLabel}
                    dropdownMenuLinks={dropdownLinks?.items}
                  />
                )}
                {replace_label_with_dropdown && hasOwnLinksCollection && (
                  <LinksList
                    links={own_links.items}
                    label={ownLinksLabel}
                    dropdownMenuLinks={dropdownLinks?.items}
                  />
                )}
                {!replace_label_with_dropdown && hasOwnLinksCollection && (
                  <LinksList
                    links={own_links.items}
                    label={ownLinksLabel}
                    dropdownMenuLinks={[]}
                  />
                )}
                {hasDefaultLinksCollection &&
                  links.map((link) => {
                    if (link.type === 'nested_buttons') return null;
                    if (replace_label_with_dropdown && link.current_post)
                      return null;

                    return link.url ? (
                      <Link key={link.guid} href={link.url} passHref>
                        <a
                          className={clsx('hover:underline flex items-center', {
                            'font-extrabold': link.current_post,
                          })}
                        >
                          {link?.post_title}
                          {link.is_root_post && (
                            <span className="h-12 w-1 bg-neutral-3 ml-16" />
                          )}
                        </a>
                      </Link>
                    ) : (
                      <div key={link.guid} className="flex items-center">
                        <div
                          className={clsx({
                            'font-extrabold': link.current_post,
                          })}
                        >
                          {link?.post_title}
                        </div>
                        {link.is_root_post && (
                          <div className="h-12 w-1 bg-neutral-3 ml-16" />
                        )}
                      </div>
                    );
                  })}
              </ul>
            </nav>
          )}
          {hasButtonsCollection && (
            <div
              className="flex gap-16 items-center whitespace-nowrap md:absolute md:right-0"
              ref={staticElementRef}
            >
              {buttons.map((item) => {
                const { button } = item;

                return (
                  <Button
                    key={button.title}
                    size="small"
                    href={button.url}
                    {...button}
                  >
                    {button.title}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AdditionalMenu;
