import dynamic from 'next/dynamic';
import { Props } from 'react-select';
import { defaultSelectProps } from './defaultSelectProps';
import clsx from 'clsx';
import { Theme } from '@components/containers/Form';

const ReactSelect = dynamic(() => import('react-select'), { ssr: false });

const Select = (
  props: Props & {
    label?: string;
    error?: any;
    selectTheme?: Theme;
  },
) => {
  const { label, error } = props;

  return (
    <div>
      {!!label && (
        <div
          className={clsx('text-sm mb-8', {
            'text-denotive-error-50': error,
            'text-neutral-9': !error,
          })}
        >
          {label}
        </div>
      )}
      <ReactSelect
        aria-label={label || ''}
        {...defaultSelectProps}
        {...props}
        classNamePrefix="react-select"
        //append to body to prevent menu option's displaying below another DOM elements
        menuPortalTarget={
          typeof document !== 'undefined' ? document.body : undefined
        }
      />
    </div>
  );
};

export default Select;
