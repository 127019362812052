import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useWindowSize from 'src/hooks/useWindowSize';

type SetScrollXState = Dispatch<SetStateAction<number>>;
type SetIsScrollEndState = Dispatch<SetStateAction<boolean>>;

const slide = (
  offset: number,
  scrollableElementRef: React.MutableRefObject<any>,
  setScrollX: SetScrollXState,
  setIsScrollEnd: SetIsScrollEndState,
) => {
  scrollableElementRef.current.scrollLeft += offset;
  setScrollX(scrollX + offset);

  if (
    Math.floor(
      scrollableElementRef.current.scrollWidth -
        scrollableElementRef.current.scrollLeft,
    ) <= scrollableElementRef.current.offsetWidth
  ) {
    setIsScrollEnd(true);
  } else {
    setIsScrollEnd(false);
  }
};

const scrollCheck = (
  scrollableElementRef: React.MutableRefObject<any>,
  setScrollX: SetScrollXState,
  setIsScrollEnd: SetIsScrollEndState,
) => {
  setScrollX(scrollableElementRef.current.scrollLeft);
  if (
    Math.floor(
      scrollableElementRef.current.scrollWidth -
        scrollableElementRef.current.scrollLeft,
    ) <= scrollableElementRef.current.offsetWidth
  ) {
    setIsScrollEnd(true);
  } else {
    setIsScrollEnd(false);
  }
};

const SCROLL_OFFSET = 50;

const useScrollElement = (
  elementRef: React.MutableRefObject<any>,
  offset: number = SCROLL_OFFSET,
) => {
  const [scrollX, setScrollX] = useState(0);
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    setScrollX(0);
    setIsScrollEnd(false);
    if (elementRef && elementRef.current) elementRef.current.scrollLeft = 0;
  }, [elementRef, width]);

  return {
    scrollX,
    isScrollEnd,
    sliderPrevHandler: () =>
      slide(-offset, elementRef, setScrollX, setIsScrollEnd),
    sliderNextHandler: () =>
      slide(+offset, elementRef, setScrollX, setIsScrollEnd),
    scrollCheckHandler: () =>
      scrollCheck(elementRef, setScrollX, setIsScrollEnd),
  };
};

export default useScrollElement;
