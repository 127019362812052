import clsx from 'clsx';
import omit from 'lodash/omit';
import React, { ButtonHTMLAttributes } from 'react';
import ChevronRightIcon from '../../../../assets/images/icons/chevron-right.svg';

export type NavigationVariant = 'LEFT' | 'RIGHT';

export interface NavigationButtonProps {
  variant: NavigationVariant;
}

const NavigationButton = (
  props: NavigationButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  const { variant } = props;
  return (
    <button
      className={clsx(
        'absolute right-0 w-36 lg:w-72 h-full flex items-center',
        {
          'left-0 bg-gradient-to-l from-transparent via-neutral-0 to-neutral-0':
            variant === 'LEFT',
          'right-0 bg-gradient-to-r from-transparent via-neutral-0 to-neutral-0':
            variant === 'RIGHT',
        },
      )}
      {...omit(props, 'variant')}
    >
      <div
        className={clsx('w-full flex justify-end p-8', {
          'rotate-180': variant === 'LEFT',
        })}
      >
        <ChevronRightIcon fill="black" />
      </div>
    </button>
  );
};

NavigationButton.defaultProps = {
  variant: 'RIGHT',
};

export default NavigationButton;
