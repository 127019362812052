import React from 'react';
import Tippy from '@tippyjs/react';
import RemoveIcon from '../../../../../assets/images/icons/remove-indicator.svg';
import ChevronDownIcon from '../../../../../assets/images/icons/chevron-down.svg';
import clsx from 'clsx';

export interface DropdownProps {
  label: string;
  resetHandler?: () => void;
  hasArrow?: boolean;
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  label,
  children,
  resetHandler,
  hasArrow,
}) => {
  return (
    <Tippy
      content={<div className="flex flex-col min-w-[18.2rem]">{children}</div>}
      placement="bottom-end"
      interactive
      animation="shift-away"
      className="rounded-4 border-1 border-neutral-2 overflow-hidden bg-neutral-0"
    >
      {/* //div wrapper is needed to proper working Tippy */}
      <div className="flex items-center gap-8 group">
        <div className="transition-colors flex items-center cursor-pointer group-aria-expanded:underline">
          {label}
          {resetHandler && (
            <button
              onClick={resetHandler}
              className="ml-8 text-neutral-4 cursor-pointer transition-opacity hover:opacity-60"
            >
              <RemoveIcon fill="currentColor" />
            </button>
          )}
          {hasArrow && (
            <div
              className={clsx(
                `text-neutral-5 ml-4 transform transition-transform group-aria-expanded:-rotate-180`,
              )}
            >
              <ChevronDownIcon fill="currentColor" />
            </div>
          )}
        </div>
      </div>
    </Tippy>
  );
};
export default Dropdown;
