import { useEffect, useState } from 'react';
import { getElementWidth } from 'src/utils/getElementWidth';
import useWindowSize from './useWindowSize';

const xlScreenWidth = 1200;

type DOMElementRef = HTMLElement | null;

const useSetFlexElementMaxWidth = (
  dynamicWidthElementRefValue: DOMElementRef,
  staticElementRefValue: DOMElementRef,
  wrapperElementRefValue: DOMElementRef,
) => {
  const [elementMaxWidth, setElementMaxWidth] = useState<number>();

  const { width } = useWindowSize();
  const containerElementWidth = getElementWidth(wrapperElementRefValue);
  const staticElementWrapperWidth = getElementWidth(staticElementRefValue);

  const isCalculable =
    staticElementWrapperWidth > 0 &&
    containerElementWidth > 0 &&
    dynamicWidthElementRefValue;

  useEffect(() => {
    if (width && width <= xlScreenWidth) {
      setElementMaxWidth(+containerElementWidth);
    } else {
      if (isCalculable) {
        const newWidth = +containerElementWidth - +staticElementWrapperWidth;
        if (newWidth !== elementMaxWidth) {
          setElementMaxWidth(newWidth);
        }
      }
    }
  }, [
    containerElementWidth,
    elementMaxWidth,
    isCalculable,
    staticElementWrapperWidth,
    width,
  ]);

  return elementMaxWidth;
};

export default useSetFlexElementMaxWidth;
