import ChevronDownIcon from '../../../assets/images/icons/chevron-down.svg';
import RemoveIcon from '../../../assets/images/icons/remove-indicator.svg';
import { Props, components } from 'react-select';
import { SelectStyles } from './SelectStyles';
import clsx from 'clsx';

export const defaultSelectProps: Props = {
  noOptionsMessage: () => 'Brak opcji',
  styles: SelectStyles,
  placeholder: 'Wybierz',
  hideSelectedOptions: false,
  components: {
    DropdownIndicator: (base) => {
      return (
        <div
          {...base.innerProps}
          className={clsx(
            `text-neutral-5 ml-4 mr-12 transform transition-transform`,
            {
              '-rotate-180': base.isFocused,
              'opacity-20': base.isDisabled,
            },
          )}
        >
          <ChevronDownIcon fill="currentColor" />
        </div>
      );
    },
    MultiValueRemove: (base) => {
      return (
        <div
          {...base.innerProps}
          className="text-neutral-0 ml-8 mr-4 cursor-pointer transition-opacity hover:opacity-60"
        >
          <RemoveIcon fill="currentColor" />
        </div>
      );
    },
    ClearIndicator: (base) => {
      return (
        <div
          {...base.innerProps}
          className="react-select__clear-indicator text-neutral-4 cursor-pointer transition-opacity hover:opacity-60"
        >
          <RemoveIcon fill="currentColor" />
        </div>
      );
    },
    MenuList: (props) => {
      return (
        <components.MenuList
          {...props}
          className={clsx(props.className, 'overflow-auto')}
        >
          {props.children}
        </components.MenuList>
      );
    },
  },
};
