import { IPostTypes } from 'src/services/api/types/IPostTypes';
import { theme } from '@styles/theme';
import ChevronIcon from '../../../../../assets/images/icons/chevron-right.svg';
import Link from 'next/link';
import React from 'react';
import { usePageColors } from 'src/hooks/usePageColors';

const Item = ({
  title,
  url,
  isActive,
  onClick,
}: {
  title: string;
  url?: string;
  isActive?: boolean;
  onClick?: () => void;
  postType?: IPostTypes;
}) => {
  const colors = usePageColors();
  const accentColor = colors?.main || theme.colors.neutral[5];
  const Inner = (
    <span
      className="py-8 flex items-center transition-opacity hover:opacity-70 group"
      style={{
        color: isActive ? accentColor : '',
      }}
    >
      <ChevronIcon
        fill={isActive ? accentColor : theme.colors.neutral[9]}
        className="mr-14 transform transition-transform group-hover:translate-x-2"
      />
      {title}
    </span>
  );
  if (url) {
    return url.includes('#') ? (
      <a href={url}>{Inner}</a>
    ) : (
      <Link href={url}>
        <a>{Inner}</a>
      </Link>
    );
  }
  if (onClick) {
    return <button onClick={onClick}>{Inner}</button>;
  }

  return Inner;
};

export default Item;
