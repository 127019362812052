import React, { Fragment } from 'react';
import Select from '@components/views/Select';
import ChevronDownIcon from '../../../../assets/images/icons/chevron-down.svg';
import {
  IItemLink,
  IItemNestedLinks,
  NestedButtons,
} from '@components/views/AdditionalMenu/LinksMenu/types';
import { SecondaryMenuProps } from '@components/containers/SecondaryMenu';
import clsx from 'clsx';
import {
  BasicLink,
  MenuLink,
} from '@components/views/AdditionalMenu/LinksMenu/Links';
import Dropdown from '@components/views/AdditionalMenu/LinksMenu/Dropdown';
import { IImage } from 'src/services/api/types/IImage';
import { useRouter } from 'next/router';
import { StylesConfig } from 'react-select';
import { theme } from '@styles/theme';
import Link from 'next/link';
import { ILinkACF } from '../../../../types/ILinkACF';

const { colors } = theme;

const SelectStyles: StylesConfig = {
  control: (base) => ({
    ...base,
    borderColor: 'transparent',
    boxShadow: 'none',
    ':hover': {
      borderColor: 'transparent',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: (base) => ({
    ...base,
    padding: '0',
    margin: '0',
  }),
  menu: (base) => ({
    ...base,
    border: `1px solid ${colors.neutral[2]}`,
    boxShadow: `0px 1px 0px ${colors.neutral[2]}`,
    marginTop: 21,
    padding: '.4rem',
    width: 'auto',
  }),
  menuList: (base) => ({
    ...base,
    whiteSpace: 'nowrap',
  }),
  option: (base, { isSelected, isFocused, isDisabled }) => ({
    ...base,
    transitionProperty: 'background-color color',
    transitionDuration: '.1s',
    color: isDisabled
      ? colors.neutral[2]
      : isSelected || isFocused
      ? colors.neutral[9]
      : colors.neutral[4],
    padding: '.8rem',
    fontWeight: isSelected ? 800 : 400,
    background:
      isSelected || isDisabled
        ? 'transparent'
        : isFocused && !isDisabled
        ? colors.neutral[2]
        : '',
    pointerEvents: isDisabled ? 'none' : 'auto',
    '&:hover': {
      background: colors.neutral[1],
      color: colors.neutral[9],
    },
  }),
  placeholder: (base) => ({
    ...base,
    paddingLeft: '0',
    color: colors.neutral[5],
    margin: '0',
  }),
};

const LinksList = (props: {
  links?: (IItemLink | IItemNestedLinks | NestedButtons)[];
  label?: SecondaryMenuProps['label'];
  dropdownMenuLinks?: { link: ILinkACF }[];
}) => {
  const { links, label, dropdownMenuLinks } = props;
  const router = useRouter();
  const dropdownOptions = dropdownMenuLinks?.map((item) => {
    return {
      label: item.link?.title,
      value: item.link?.url,
    };
  });

  return (
    <Fragment>
      {!!label && dropdownMenuLinks?.length == 0 && (
        <li className="flex items-center">
          <div
            className={clsx(
              'inline-block flex items-center font-extrabold text-sm',
            )}
          >
            {label}
          </div>
          <span className="h-12 w-1 bg-neutral-3 ml-16" />
        </li>
      )}

      {dropdownMenuLinks && dropdownMenuLinks.length > 0 && (
        <Select
          options={dropdownOptions}
          placeholder={label}
          styles={{
            ...SelectStyles,
            placeholder: (base) => ({
              ...base,
              color: colors.neutral[9],
              fontWeight: 800,
            }),
          }}
          isSearchable={false}
          components={{
            DropdownIndicator: (base) => {
              return (
                <div
                  {...base.innerProps}
                  className={clsx(
                    `text-neutral-9 ml-4 pr-2 transform transition-transform`,
                    {
                      '-rotate-180': base.isFocused,
                      'opacity-20': base.isDisabled,
                    },
                  )}
                >
                  <ChevronDownIcon fill="currentColor" />
                </div>
              );
            },
          }}
          onChange={async (data: any) => {
            await router.replace(
              {
                pathname: data.value,
              },
              undefined,
            );
          }}
        />
      )}

      {links?.map((item) => {
        if (item.acf_fc_layout === 'simple_link') {
          return (
            <li key={item.link.title}>
              <BasicLink link={item.link} />
            </li>
          );
        }

        if (item.acf_fc_layout === 'nested_links') {
          const options = item.links.map((link) => {
            return {
              label: link.link.title,
              value: link.link.url,
            };
          });

          return (
            <li key={item.name}>
              {item.display_as_select && (
                <Select
                  options={options}
                  placeholder={item.placeholder}
                  styles={{
                    ...SelectStyles,
                  }}
                  isSearchable={false}
                  components={{
                    DropdownIndicator: (base) => {
                      return (
                        <div
                          {...base.innerProps}
                          className={clsx(
                            `text-neutral-9 ml-4 pr-2 transform transition-transform`,
                            {
                              '-rotate-180': base.isFocused,
                              'opacity-20': base.isDisabled,
                            },
                          )}
                        >
                          <ChevronDownIcon fill="currentColor" />
                        </div>
                      );
                    },
                  }}
                  onChange={async (data: any) => {
                    await router.replace(
                      {
                        pathname: data.value,
                      },
                      undefined,
                    );
                  }}
                />
              )}
              {!item.display_as_select && (
                <Dropdown label={item.name}>
                  {item.links.map(({ link, icon }, index) => {
                    return (
                      <div key={link.title}>
                        <MenuLink
                          title={link.title}
                          url={link.url}
                          icon={icon as IImage}
                          target={link.target}
                        />
                        {item.links.length > index + 1 && (
                          <div className="h-1 w-[95%] mx-auto bg-neutral-2" />
                        )}
                      </div>
                    );
                  })}
                </Dropdown>
              )}
            </li>
          );
        }

        if (item.acf_fc_layout === 'nested_buttons') {
          return (
            <li key={item.name}>
              <Dropdown
                label={item.name}
                resetHandler={item.resetHandler}
                hasArrow={true}
              >
                <div className="mx-4 my-6">
                  {item.links.map((link) => {
                    const { active, url, name } = link;
                    const classes = clsx(
                      ' bg-neutral-0 transition-colors flex items-center px-8 py-6 text-neutral-9 text-sm w-full',
                      {
                        'disabled font-bold hover:bg-neutral-0 cursor-default':
                          active,
                        'hover:bg-neutral-1 cursor-pointer': !active,
                        'pointer-events-none': active,
                      },
                    );
                    if (active) {
                      return (
                        <div key={name} className={classes}>
                          {name}
                        </div>
                      );
                    }
                    return (
                      <Link href={url} key={name}>
                        <a className={classes}>{name}</a>
                      </Link>
                    );
                  })}
                </div>
              </Dropdown>
            </li>
          );
        }
        return <></>;
      })}
    </Fragment>
  );
};

export default LinksList;
