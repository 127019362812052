import { StylesConfig } from 'react-select';
import { theme } from '@styles/theme';

const { colors } = theme;

export const SelectStyles: StylesConfig = {
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    transition: 'border-color .3s',
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: colors.neutral[1],
    '&:hover': { borderColor: colors.neutral[2] },
  }),
  valueContainer: (base, options) => {
    const { isMulti, hasValue } = options;

    return {
      ...base,
      width: '100%',
      padding: isMulti && hasValue ? '.8rem' : '1.2rem 1.2rem',
      gap: '0.4rem',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: colors.neutral[9],
    padding: '.4rem',
    borderRadius: '.4rem',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  }),
  indicatorsContainer: (base, { isDisabled }) => ({
    ...base,
    backgroundColor: isDisabled && colors.neutral[1],
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontWeight: 500,
    fontSize: '1.4rem',
    color: '#fff',
    lineHeight: '2.4rem',
    padding: 0,
  }),
  placeholder: (base) => ({
    ...base,
    paddingLeft: '0',
    color: colors.neutral[5],
    margin: '0',
  }),
  option: (base, { isSelected, isFocused, isDisabled }) => ({
    ...base,
    transitionProperty: 'background-color color',
    transitionDuration: '.1s',
    color: isDisabled
      ? colors.neutral[2]
      : isSelected || isFocused
      ? colors.neutral[9]
      : colors.neutral[4],
    padding: '.8rem',
    fontWeight: isSelected ? 800 : 400,
    background:
      isSelected || isDisabled
        ? 'transparent'
        : isFocused && !isDisabled
        ? colors.neutral[2]
        : '',
    pointerEvents: isDisabled ? 'none' : 'auto',
    '&:hover': {
      background: colors.neutral[1],
      color: colors.neutral[9],
    },
  }),
  input: (base) => ({
    ...base,
    padding: '0',
    margin: '0',
  }),
  menu: (base) => ({
    ...base,
    padding: '.4rem',
    boxShadow: `0px 1px 0px ${colors.neutral[2]}`,
    border: `1px solid ${colors.neutral[2]}`,
  }),
};
