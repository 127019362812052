import React, { useState } from 'react';
import {
  InnerLink,
  SecondaryMenuProps,
} from '@components/containers/SecondaryMenu';
import { useAdditionalMobileMenu } from '@components/views/AdditionalMenuMobile/additionalMobileMenu.context';
import Item from '@components/views/AdditionalMenuMobile/AccordionItems/List/Item';
import RemoveIcon from '../../../../../assets/images/icons/remove-indicator.svg';

const isSomeChildActive = (links: InnerLink[]) => {
  if (!links) return false;

  return links.some((link) => link.current_post);
};

const List: React.FunctionComponent<{
  items: SecondaryMenuProps['links'];
}> = ({ items }) => {
  const [activeItem, setActiveItem] = useState<number | undefined>(undefined);
  const { handleMenuToggle } = useAdditionalMobileMenu();
  const handleActiveItem = (index: number) => {
    if (index === activeItem) {
      setActiveItem(undefined);
      return;
    }

    setActiveItem(index);
  };

  return (
    <ul className="p-16 max-w-full flex flex-col gap-8">
      {items.map((link, index) => {
        if (link.type === 'nested_buttons') {
          const { name, resetHandler } = link;

          return (
            <li key={name} className="font-extrabold">
              <div className="flex items-center gap-8">
                <Item
                  title={name}
                  isActive={activeItem === index}
                  onClick={() => handleActiveItem(index)}
                />
                {resetHandler && (
                  <button onClick={resetHandler}>
                    <div className=" text-neutral-4 cursor-pointer transition-opacity hover:opacity-60">
                      <RemoveIcon fill="currentColor" />
                    </div>
                  </button>
                )}
              </div>
              {activeItem === index && (
                <ul className="pl-26">
                  {link.children.map((item) => (
                    <li key={item.name}>
                      <Item
                        title={item.name}
                        isActive={item.active}
                        url={item.url}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        }

        const isChildActive = isSomeChildActive(link.children);
        const isActive =
          (link.current_post || isChildActive) && !link.url?.includes('#');
        return (
          <li
            key={link.post_title}
            className="font-extrabold"
            onClick={link.url ? handleMenuToggle : undefined}
          >
            <Item
              title={link.post_title}
              url={link.url}
              isActive={isActive || activeItem === index}
              onClick={() => handleActiveItem(index)}
              postType={link.post_type}
            />
            {(link.current_post || isChildActive || activeItem === index) && (
              <ul className="pl-26">
                {link.children.map((item) => {
                  return (
                    <li key={item.post_title} onClick={handleMenuToggle}>
                      <Item
                        title={item.post_title}
                        url={item.url}
                        isActive={item.current_post && !link.url?.includes('#')}
                        postType={link.post_type}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
};
export default List;
