import {
  IItemLink,
  IItemNestedLinks,
  NestedButtons,
} from '@components/views/AdditionalMenu/LinksMenu/types';

export const formatOwnLinksToAdditionalMenuLinks = (
  links: (IItemLink | IItemNestedLinks | NestedButtons)[],
): any => {
  return links
    .map((link) => {
      if (link.acf_fc_layout === 'simple_link') {
        const { url, title } = link.link;
        return {
          ...link.link,
          url,
          post_title: title,
          guid: title,
          children: [],
        };
      }

      if (link.acf_fc_layout === 'nested_links') {
        const { name } = link;
        return {
          children: link.links.map((link) => {
            const { url, title } = link.link;
            return {
              ...link.link,
              url,
              post_title: title,
              guid: title,
            };
          }),
          post_title: name,
          guid: name,
          post_type: link.post_type,
        };
      }

      if (link.acf_fc_layout === 'nested_buttons') {
        return {
          children: link.links,
          ...link,
          type: 'nested_buttons',
        };
      }
    })
    .filter((link) => link);
};
