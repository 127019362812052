import Link from 'next/link';
import { IImage } from 'src/services/api/types/IImage';
import Image from 'next/image';
import React from 'react';
import { ILinkACF } from 'src/types/ILinkACF';
import clsx from 'clsx';

export const BasicLink = (props: { link: ILinkACF }) => {
  const { link } = props;
  const classes = 'hover:underline transition-colors flex items-center';

  if (!link.target) {
    return link.url.includes('#') ? (
      <a href={link.url} className={classes}>
        {link.title}
      </a>
    ) : (
      <Link href={link.url}>
        <a className={classes}>{link.title}</a>
      </Link>
    );
  }

  return (
    <a
      href={link.url}
      className={classes}
      target="_blank"
      rel="noreferrer noopener"
    >
      {link.title}
    </a>
  );
};

export const MenuLink = ({
  title,
  url,
  icon,
  target,
  active,
}: ILinkACF & {
  icon: IImage;
  active?: boolean;
}) => {
  const classes =
    'hover:bg-neutral-1 bg-neutral-0 transition-colors flex items-center p-16 text-neutral-9 text-sm cursor-pointer';

  const Inner = (
    <>
      {icon && (
        <span className="flex items-center mr-8 w-16">
          <Image
            alt={icon.title}
            src={icon.url}
            width={icon.width}
            height={icon.height}
          />
        </span>
      )}
      {title}
    </>
  );

  if (!url) return null;

  if (!target) {
    return url.includes('#') ? (
      <a
        className={clsx(classes, {
          'font-bold pointer-events-none': active,
        })}
      >
        {Inner}
      </a>
    ) : (
      <Link href={url}>
        <a
          className={clsx(classes, {
            'font-bold pointer-events-none': active,
          })}
        >
          {Inner}
        </a>
      </Link>
    );
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      className={clsx(classes, {
        'font-bold pointer-events-none': active,
      })}
    >
      {Inner}
    </a>
  );
};
