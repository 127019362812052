import React from 'react';
import { SecondaryMenuProps } from '@components/containers/SecondaryMenu';
import ButtonACF from '@components/containers/ButtonACF';
import List from '@components/views/AdditionalMenuMobile/AccordionItems/List';
import { ILinkACF } from '../../../../types/ILinkACF';

const AccordionItems: React.FunctionComponent<{
  links: SecondaryMenuProps['links'];
  buttons?: SecondaryMenuProps['buttons'];
  additionalLinks?: { link: ILinkACF }[];
}> = ({ links, buttons, additionalLinks }) => {
  return (
    <div className="absolute bg-neutral-0 w-full shadow-elevation1">
      {additionalLinks && additionalLinks.length > 0 && (
        <List
          items={additionalLinks.map((link) => {
            return {
              post_title: link.link.title,
              url: link.link.url,
              type: 'link',
              children: [],
              guid: link.link.url,
            };
          })}
        />
      )}

      <List items={links} />
      <div>
        {!!buttons &&
          buttons.map(({ button }) => (
            <div key={button.title} className="text-center mb-18">
              <ButtonACF {...button} />
            </div>
          ))}
      </div>
      <div className="h-1 w-full bg-neutral-4 absolute bottom-0 left-0" />
    </div>
  );
};
export default AccordionItems;
