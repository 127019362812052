import React from 'react';
import { theme } from '@styles/theme';
import ChevronIcon from '../../../../assets/images/icons/chevron-right.svg';
import clsx from 'clsx';

export interface AccordionTitleProps {
  title: string;
  isOpen: boolean;
  onClick: () => void;
}

const AccordionTitle: React.FunctionComponent<AccordionTitleProps> = ({
  title,
  onClick,
  isOpen,
}) => {
  return (
    <button
      className="p-16 flex items-center justify-between w-full font-extrabold bg-neutral-0"
      onClick={onClick}
    >
      {title}
      <ChevronIcon
        fill={theme.colors.neutral[9]}
        className={clsx('transform transition-transform', {
          'rotate-90': !isOpen,
          '-rotate-90': isOpen,
        })}
        style={{ width: 8, height: 12 }}
      />
    </button>
  );
};
export default AccordionTitle;
