import { createContext, FC, useContext } from 'react';

export type AdditionalMobileMenuState = {
  handleMenuToggle: () => void;
};

const AdditionalMobileMenu = createContext<
  AdditionalMobileMenuState | undefined
>(undefined);

const AdditionalMobileMenuProvider: FC<AdditionalMobileMenuState> = ({
  children,
  handleMenuToggle,
}) => {
  return (
    <AdditionalMobileMenu.Provider value={{ handleMenuToggle }}>
      {children}
    </AdditionalMobileMenu.Provider>
  );
};

const useAdditionalMobileMenu = () => {
  const ctx = useContext(AdditionalMobileMenu);

  if (ctx === undefined) {
    throw new Error(
      'useAdditionalMobileMenu must be used within a AdditionalMobileMenuProvider',
    );
  }

  return ctx;
};

export { AdditionalMobileMenuProvider, useAdditionalMobileMenu };
