import AdditionalMenu from '@components/views/AdditionalMenu';
import { IButtonACF } from 'src/types/IButtonACF';
import AdditionalMenuMobile from '@components/views/AdditionalMenuMobile';
import {
  IItemLink,
  IItemNestedLinks,
  ListButton,
  NestedButtons,
} from '@components/views/AdditionalMenu/LinksMenu/types';
import { IPostTypes } from 'src/services/api/types/IPostTypes';
import { ILinkACF } from '../../../types/ILinkACF';

export type InnerLink = {
  url: string;
  post_title: string;
  guid: string;
  is_root_post?: boolean;
  current_post?: boolean;
  post_type?: IPostTypes;
};

export interface Link extends InnerLink {
  children: InnerLink[];
  type: 'link';
}

export interface MenuButton extends ListButton {
  children: ListButton[];
  name: string;
  type: 'nested_buttons';
  resetHandler?: () => void;
}

export interface SecondaryMenuProps {
  links: Array<Link | MenuButton>;
  buttons: Array<{
    button: IButtonACF;
  }>;
  current_page?: Link;
  use_own_links?: boolean;
  own_links?: { items?: (IItemLink | IItemNestedLinks | NestedButtons)[] };
  label?: string;
  replace_label_with_dropdown?: boolean;
  links_select?: { items?: { link: ILinkACF }[] };
}

const SecondaryMenu = (props: SecondaryMenuProps) => {
  return (
    <div className="sticky top-70 z-20">
      <div className="hidden md:block">
        <AdditionalMenu {...props} />
      </div>
      <div className="md:hidden">
        <AdditionalMenuMobile {...props} />
      </div>
    </div>
  );
};

export default SecondaryMenu;
